var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _vm._m(0),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-8" },
          [
            _c(
              "b-button",
              {
                staticClass: "faq",
                attrs: { to: "/info/faq", block: "", variant: "light" }
              },
              [_vm._v("よくあるご質問はこちら")]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "header text-center" }, [
        _vm._v("チェックインと登攀記録のつくり方")
      ]),
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-12 col-md-8" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h5", { staticClass: "card-title text-left text-md-center" }, [
                _vm._v("①岩場にチェックイン")
              ]),
              _c("p", { staticClass: "card-text text-left text-md-center" }, [
                _vm._v("岩場付近500mの範囲でチェックインが可能です。"),
                _c("br"),
                _vm._v("到着したらチェックインしましょう！ ")
              ]),
              _c(
                "p",
                { staticClass: "card-text text-left text-md-center info" },
                [
                  _c("i", { staticClass: "fas fa-info-circle" }, [
                    _vm._v(
                      "必ずスマートフォンの位置情報へのアクセスを許可してください。"
                    )
                  ])
                ]
              ),
              _c("p", { staticClass: "card-text text-left text-md-center" }, [
                _c("small", [
                  _vm._v(
                    "※過去の記録を作成したい場合など、チェックインを行わず記録の作成をすることも可能です。"
                  ),
                  _c("br"),
                  _vm._v(
                    "その場合、次の手順「②登攀したら記録作成」に進んで下さい。"
                  ),
                  _c("br"),
                  _vm._v(
                    "（この場合チェックイン記録は作成されずチェックインポイントも付与対象外となります。）"
                  )
                ])
              ]),
              _c("div", { staticClass: "card-img text-center" }, [
                _c("img", {
                  attrs: {
                    src:
                      "https://devmaterial.yamalab.jp/img/guide/checkIn_img_1.png"
                  }
                })
              ]),
              _c("br"),
              _c("p", { staticClass: "card-text text-left text-md-center" }, [
                _vm._v("チェックインの履歴はマイページで参照できます。")
              ]),
              _c("div", { staticClass: "card-img text-center" }, [
                _c("img", {
                  attrs: {
                    src:
                      "https://devmaterial.yamalab.jp/img/guide/checkIn_img_2.png"
                  }
                })
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-12 col-md-8 text-center" }, [
          _c("i", { staticClass: "fas fa-chevron-down" })
        ])
      ]),
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-12 col-md-8" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h5", { staticClass: "card-title text-left text-md-center" }, [
                _vm._v("②登攀したら記録作成")
              ]),
              _c("p", { staticClass: "card-text text-left text-md-center" }, [
                _vm._v(
                  "ルートを登ったら「記録」アイコンから記録作成しましょう。"
                ),
                _c("br"),
                _vm._v(
                  "ルート単位で登攀区分（オンサイト/フラッシュ/完登/チャレンジ中）も記録できます。"
                )
              ]),
              _c("div", { staticClass: "card-img text-center" }, [
                _c("img", {
                  attrs: {
                    src:
                      "https://devmaterial.yamalab.jp/img/guide/record_img_1.png"
                  }
                })
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-12 col-md-8 text-center" }, [
          _c("i", { staticClass: "fas fa-chevron-down" })
        ])
      ]),
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-12 col-md-8" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h5", { staticClass: "card-title text-left text-md-center" }, [
                _vm._v("記録の参照")
              ]),
              _c("p", { staticClass: "card-text text-left text-md-center" }, [
                _vm._v("作成した記録はマイページから参照できます。")
              ]),
              _c("div", { staticClass: "card-img text-center" }, [
                _c("img", {
                  attrs: {
                    src:
                      "https://devmaterial.yamalab.jp/img/guide/record_img_2.png"
                  }
                })
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-12 col-md-8 text-center" }, [
          _c("i", { staticClass: "fas fa-chevron-down" })
        ])
      ]),
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _c("div", { staticClass: "col-12 col-md-8" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h5", { staticClass: "card-title text-left text-md-center" }, [
                _vm._v("記録やチェックインが増えると・・・")
              ]),
              _c("p", { staticClass: "card-text text-left text-md-center" }, [
                _vm._v(
                  "記録やチェックイン回数が増えるとレベルに応じてステータスバッジがもらえます。"
                )
              ]),
              _c("div", { staticClass: "card-img text-center" }, [
                _c("img", {
                  attrs: {
                    src:
                      "https://devmaterial.yamalab.jp/img/guide/badge_img_1.png"
                  }
                })
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }